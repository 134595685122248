.ga__activities-card-container{
    background: #FF84000D;
}

.ga__activities-card-container .activites-titile .title{
    font-family: El Messiri;
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
    text-align: center;
    



}
.ga__activities-card-container .activites-description .description{
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 20.8px;
text-align: center;
}

.activities-card-container-item{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding: 20px;
    column-gap: 40px;
}

@media(max-width:991px){
    .activities-card-container-item{
        grid-template-columns: 1fr;
    }
}
.activity-card {
    border: 1px solid #DCDCDC;
    padding: 15px;
    border-radius: 20px;
    background-color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .activity-image {
    height: auto;
    border: 1px solid #0000001A;
    border-radius: 20px;
  }
  
  .activity-title {
    font-family: El Messiri;
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
    text-align: left;
    color: #000000;

  }
  
  .activity-description  {
    font-family: Inter;
font-size: 15px;
font-weight: 400;
line-height: 19.5px;
text-align: left;
margin-bottom: 0;
    color: #9B9B9B;
  }